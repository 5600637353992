import {
  CHECK_PHONE,
  GET_OTP, SET_USER_INFO, VERIFY_OTP
} from "./actions.type";

import {otp} from "../service/otp";

function initState() {
  return {
    user: {},
    otpRefCode: null
  }
}

const state = initState();

const actions = {

  async [GET_OTP]({commit}, data) {
    let response = await otp.get(data);
    if (response.data) {
      commit(GET_OTP, response.data.otpRefCode);
    }
    return response;
  },

  async [CHECK_PHONE]({commit}, data) {
    let response = await otp.checkPhone(data);
    if (response.data) {
      commit(CHECK_PHONE, response.data.phoneNo);
    }
    return response;
  },

  async [VERIFY_OTP]({commit}, data) {
    return await otp.verify(data);
  },

  async [SET_USER_INFO]({commit}, data) {
    commit(SET_USER_INFO, data);
  },

};

const mutations = {
  [GET_OTP](state, data) {
    state.otpRefCode = data;
  },
  [SET_USER_INFO](state, data) {
    state.user = data;
  },
  [CHECK_PHONE](state, data) {
    state.phone = data;
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters
};
