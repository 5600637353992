import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
import otp from './otp'

export default new Vuex.Store({
  modules: {
    otp,
  }
})
