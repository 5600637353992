import axios from 'axios';
import {API_URL} from "../config/constant";

export const otp = {
  checkPhone(data) {
    return axios({
      method: 'POST',
      url: `${API_URL}/checkPhone`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(function (response) {
      return response.data
    }).catch((e) => {
      if (!e?.response?.status) {
        return false;
      }

      return e.response.data
    });
  },

  get(data) {
    return axios({
      method: 'POST',
      url: `${API_URL}/devicesRegister`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(function (response) {
      return response.data
    }).catch((e) => {
      if (!e?.response?.status) {
        return false;
      }

      return e.response.data
    });
  },

  verify(data) {
    return axios({
      method: 'POST',
      url: `${API_URL}/devicesConfirm`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(function (response) {
      return response.data
    }).catch((e) => {
      if (!e?.response?.status) {
        return false;
      }

      return e.response.data
    });
  },

};
