let config;

if (process.env.NODE_ENV === 'production') {
    config = {
        API_URL: 'https://mket-proxy-prod.stockradars.co',
    };
} else {
    config = {
      API_URL: 'https://mket-proxy.stockradars.co',
    };
}

module.exports = config;

